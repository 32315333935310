<template>
  <div class="menu">
    <div class="actionBar">
      <a-button
        type="primary"
        @click="showModal"
        v-if="handLists.top.indexOf('add') != -1"
      >
        新增权限
      </a-button>
    </div>
    <!-- 添加新菜单 -->
    <a-modal
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
    >
      <a-form
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        :from="addFromData"
      >
        <a-form-item label="父级菜单">
          <a-tree-select
            v-model="addFromData.parent_id"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="addMenu"
            placeholder="选择父级菜单，一级则不选"
            tree-default-expand-all
            :replaceFields="{ value: 'id' }"
            allowClear
            @change="menuSelect"
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item label="菜单名称">
          <a-input v-model="addFromData.title" />
        </a-form-item>
        <a-form-item label="后端地址">
          <a-input v-model="addFromData.url" />
        </a-form-item>
        <a-form-item label="WEB路由">
          <a-input v-model="addFromData.uri" />
        </a-form-item>
        <a-form-item label="图标">
          <a-input v-model="addFromData.icon" />
        </a-form-item>
        <a-form-item label="排序">
          <a-input-number :min="0" v-model="addFromData.list_order" />
        </a-form-item>
        <a-form-item label="状态">
          <a-radio-group v-model="addFromData.status">
            <a-radio :value="1">
              启用
            </a-radio>
            <a-radio :value="0">
              禁用
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="菜单类型">
          <a-radio-group v-model="addFromData.menu_type">
            <a-radio :value="1">
              菜单
            </a-radio>
            <a-radio :value="2">
              按钮
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="按钮操作位置" v-if="addFromData.menu_type == 2">
          <a-radio-group v-model="addFromData.handle_position">
            <a-radio :value="1">
              顶部
            </a-radio>
            <a-radio :value="2">
              右侧
            </a-radio>
            <a-radio :value="3">
              底部
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>

    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="false"
    >
      <span slot="status" slot-scope="text">
        <a-tag :color="text == 1 ? 'green' : 'orange'">
          {{ text == 1 ? "启用" : "禁用" }}
        </a-tag>
      </span>

      <span slot="menu_type" slot-scope="text">
        <a-tag :color="text == 1 ? 'green' : 'orange'">
          {{ text == 1 ? "菜单" : "按钮" }}
        </a-tag>
      </span>

      <span slot="action" slot-scope="record">
        <span
          v-if="
            record.menu_type == 1 && handLists.right.indexOf('addSub') != -1
          "
        >
          <FontIcon
            @click.native="addSubMenu(record)"
            title="添加子菜单"
            type="icon-fenzhi"
            size="22"
          ></FontIcon>
          <a-divider type="vertical" />
        </span>

        <span v-if="handLists.right.indexOf('edit') != -1">
          <FontIcon
            @click.native="editMenu(record)"
            title="修改"
            type="icon-xiugai"
            size="22"
          ></FontIcon>
          <a-divider type="vertical" />
        </span>

        <a-popconfirm
          title="确定删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(record)"
          v-if="handLists.right.indexOf('del') != -1"
        >
          <FontIcon title="删除" type="icon-lajitong" size="22"></FontIcon>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
import { menuIndex, menuDataSave, menuAddMenu, menuDelete } from "@/apis/index";
import { watch } from "fs";
const columns = [
  {
    title: "菜单名称",
    dataIndex: "title",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "菜单ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    // align: "center",
  },
  {
    title: "排序",
    dataIndex: "list_order",
    scopedSlots: { customRender: "list_order" },
  },
  {
    title: "WEB路由",
    dataIndex: "uri",
    scopedSlots: { customRender: "uri" },
  },
  {
    title: "后端地址",
    dataIndex: "url",
    scopedSlots: { customRender: "url" },
  },
  {
    title: "图标",
    dataIndex: "icon",
    scopedSlots: { customRender: "icon" },
  },
  {
    title: "状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "类型",
    dataIndex: "menu_type",
    scopedSlots: { customRender: "menu_type" },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      addMenu: [],
      modalTitle: "",
      addFromData: {},
      handLists: this.$store.state.menuHandleList,
    };
  },
  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists.top = val.top ? val.top : [];
      this.handLists.right = val.right ? val.right : [];
    },
  },
  created() {
    this.getMenuIndex();
    this.getMenuAddMenu();
  },
  methods: {
    confirm(rec) {
      menuDelete({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getMenuIndex();
          this.getMenuAddMenu();
        }
      });
    },
    menuSelect(a, b, c) {},
    getMenuIndex() {
      menuIndex().then((res) => {
        this.data = res.data;
      });
    },
    getMenuAddMenu() {
      menuAddMenu().then((res) => {
        this.addMenu = res.data;
      });
    },
    showModal() {
      this.modalTitle = "新增菜单";
      (this.addFromData = {
        id: "",
        title: "",
        uri: "",
        url: "",
        parent_id: undefined,
        list_order: 1,
        icon: "",
        status: 1,
        menu_type: 1,
        handle_position: 0,
      }),
        (this.visible = true);
    },
    handleOk(e) {
      this.confirmLoading = true;
      menuDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.$message.success("保存成功");
          this.getMenuIndex();
          this.getMenuAddMenu();
          this.visible = false;
        }
        this.confirmLoading = false;
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    addSubMenu(record) {
      this.modalTitle = "添加子菜单";
      this.addFromData = {
        id: "",
        title: "",
        uri: "",
        url: "",
        parent_id: record.id,
        list_order: 1,
        icon: "",
        status: 1,
        menu_type: 1,
        handle_position: 0,
      };
      this.visible = true;
    },
    editMenu(record) {
      this.modalTitle = "修改菜单信息";
      this.addFromData = { ...record };
      if (this.addFromData.parent_id == 0) {
        this.addFromData.parent_id = undefined;
      }
      this.visible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.menu {
  .actionBar {
    padding: 30px 0;
  }
}
</style>
